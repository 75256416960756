import React from 'react'
import About from '../components/About'
import Customers from '../components/Customers/customers'
import HeroContent from '../components/HeroContent'
import Navbar from '../components/Navbar'
import Product from '../components/Products'
import Footer from '../components/Footer'

const homePage = () => {
  return (
    <div>
      <Navbar/>
      <HeroContent />
      <About />
      <Product />
      <Customers />
      <Footer />
    </div>
  )
}

export default homePage
import React from 'react';
import { Link } from "react-router-dom";
import SimpleMap from './simpleMap';

const FooterCpt = () => {
  return (
    <footer className="page-footer brown lighten-1">
      <div className="container">
        <div className="row">
          <div className="col l3 s12">
            <h5 className="white-text"><u className="this-about">
              LWS Tendas
            </u></h5>
            <ul>
            <li className="page-scroll"><a className="white-text" href="#how">Quem somos</a></li>
              <li className="page-scroll"><a className="white-text" href="#products">Produtos</a></li>
              <li className="page-scroll"><a className="white-text" href="#cases">Cases</a></li>
              <li><Link to="/contact" className="white-text">Contatos</Link></li>
            </ul>
          </div>

          <div className="col l3 s12">
            <ul>
              <li>
                <p>
                  <div className="white-text">
                    Ronald Robert's <br/>
                    (62) 99479-5640 <i className="fa fa-whatsapp" aria-hidden="true"></i>
                    <hr/>
                  </div>
                </p>
              </li>
            </ul>
          </div>

          <div className="col l6 s12">
            <ul>
              <li>
                <p className="white-text">
                  São Paulo:<br/>(19) 98976-2168<br/>
                </p>
                <SimpleMap />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <div className="container">
        <span className="right">Create by <a href="https://devborne.com/" className=" blue-text text-lighten-3">Devborne</a></span>
        <span className="left">AV. JK/DO PEDRO II Qd.3 Lt.23 - Jardim Transbrasiliano - Aparecida de Goiânia</span>
        </div>
      </div>
    </footer>
  )
}

export default FooterCpt;

import React from 'react';
import backgroundContent from './assets/background-hero-content.jpg';


const HeroContent = () => {

  return (
    <div class="container-about">
      <img src={backgroundContent} alt='background-hero-content' className="container-about-img" />
      <div class="centered">
        <div className="container">
          <h1 className="header center deep-orange-text text-lighten-2">LWS Tendas</h1>
          <div className="row center">
            <div className="logo_lws header col s12">
              <h1 className="black-text deep-orange darken-4">
                Tudo em tendas e Galvanização
              </h1>
            </div>
          </div>
         </div>
      </div>
    </div>
  )
}

export default HeroContent
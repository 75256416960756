import React from 'react';
import { Navbar, NavItem, Icon } from 'react-materialize';
import { Link } from 'react-router-dom';
import logo from './assets/logo.png';

function NavbarCpt() {

  return (
    <Navbar
      alignLinks="right"
      brand={<Link to='/'><img src={logo} alt="lws-logo"/></Link>}
      id="mobile-nav"
      menuIcon={<Icon className="menu-button">menu</Icon>}
      className="white"
      options={{
        draggable: true,
        edge: 'left',
        inDuration: 250,
        onCloseEnd: null,
        onCloseStart: null,
        onOpenEnd: null,
        onOpenStart: null,
        outDuration: 200,
        preventScrolling: true
      }}
    >
      <NavItem>
        Quem somos
      </NavItem>
      <NavItem>
        Produtos
      </NavItem>
      <NavItem>
        Clientes
      </NavItem>
      <NavItem>
        <Link to="/contact">Contato</Link>
      </NavItem>
      <NavItem>
        <a href="tel:+556232783779" class="fone">(62) 3278-3779</a>
      </NavItem>
    </Navbar>
  )
}

export default NavbarCpt;
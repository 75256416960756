import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class SimpleMap extends Component {
  render() {

    const defaultValues = {
      center: {
        lat: -16.7655761,
        lng: -49.2431809
      },
      position: {
        lat: -16.7655761,
        lng: -49.2431809
      },
      zoom: 11
    };

    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '350px', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyAsdsFdfQWm9M57BwD5LBhFJZTg16umQYg' }}
          defaultCenter={defaultValues.center}
          defaultZoom={defaultValues.zoom}
        >
          <AnyReactComponent
            lat={defaultValues.position.lat}
            lng={defaultValues.position.lng}
            text="LWS Tendas"
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default SimpleMap;
import {useState} from 'react';
const emailjs = require('emailjs-com');

function Form(){
    const [name, setName] = useState();   
    const [email, setEmail] = useState();   
    const [phone, setPhone] = useState();   
    const [message, setMessage] = useState();   

    const template_params = {
        name : '',
        email : '',
        phone : '', 
        message : ''
    };
    


    function onChange(e){
        const {name, value} = e.target;

            if(name === 'name'){
                setName(value);
            } else if(name === 'email'){
                setEmail(value);
            } else if(name === 'phone'){
                setPhone(value);
            }else if(name === 'message'){
                setMessage(value);
            }
    }

    function send(e){

        const config = {
            user_key : 'user_wVB6lpZZlBPTbsnUwrTzZ',
            acess_token : '9e4f593ec24b93b56aceb42a625cc21a',
            service_id : 'lwstendas',
            template_id : 'template_aw8dqzb'
        };

        e.preventDefault();

        template_params.name = name;
        template_params.email = email;
        template_params.phone = phone;
        template_params.message = message;

        console.log(template_params);

        emailjs.send(config.service_id, config.template_id, template_params, config.user_key)
            .then( resp => {
                console.log('Sucess : ', resp)
                alert("Mensagem Enviada com sucesso");
            }).catch(err => {
                console.log(err)
                alert("Mensagem Enviada com sucesso");
            });
    }

    return(

        <div class="container-contacts">
            <div class="container">
                <div class="section">
    
    
                    <div class="row" id="contacts">
                        <div class="col s12 m12">
    
                            <div class="col s12 center">
    
                                <h4 class="title-harvy">Contato</h4>
                                <hr></hr>
                                <form> 
                                        <label>
                                            Nome :
                                            <input type="text" name="name" onChange={onChange}></input>
                                        </label>

                                        <label>
                                            Email :
                                            <input type="text" name="email" onChange={onChange}></input>
                                        </label>

                                        <label>
                                            Telefone :
                                            <input type="text" name="phone" onChange={onChange}></input>
                                        </label>

                                        <label>
                                            Mensagem :
                                            <textarea type="text" name="message" onChange={onChange}></textarea>
                                        </label>
                                        <input type="submit" className = "waves-effect waves-light btn brown lighten-1" onClick={send} value="Enviar"></input>

                                    </form>
                            </div>

                        </div>
    
    
                    </div>
    
                </div>
            </div>
      </div>

    );
}


export default Form;
import {BrowserRouter, Route} from 'react-router-dom';

import Contact from './pages/Contact';
import Home from './pages/Home';

import Contencoes from './pages/Products_pages/Contencoes';
import Galpoes from './pages/Products_pages/Galpoes';
import Personalizadas from './pages/Products_pages/Personalizadas';
import Piramides from './pages/Products_pages/Piramides';
import Sanfonadas from './pages/Products_pages/Sanfonadas';
import SobMedidas from './pages/Products_pages/SobMedidas';

function Routes(){
    return(
        <BrowserRouter>
            <Route path='/' exact component={Home}/>
            <Route path='/contact' exact component={Contact}/>
            
            <Route path='/piramides' exact component={Piramides}/>
            <Route path='/sanfonadas' exact component={Sanfonadas}/>
            <Route path='/personalizadas' exact component={Personalizadas}/>
            <Route path='/sob_medidas' exact component={SobMedidas}/>
            <Route path='/galpoes' exact component={Galpoes}/>
            <Route path='/contencoes' exact component={Contencoes}/>
        </BrowserRouter>
    );

}

export default Routes;
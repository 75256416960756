import { Slider, Slide, Caption } from 'react-materialize';
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'


function Personalizadas(){
    return (
        <div className="content-product">
          <Navbar/>
            <div className="section">
              <div className="row">
                <div className="col s12 m1"></div>
                <div className="col s12 m1">
                  <h4>Personalizadas</h4>
                  <hr/>
                </div>
                <div className="col s12 m4"></div>
                <div className="col s12 m6"></div>
              </div>
              <div className="row">
                <div className="col s12 m1"></div>
                <div className="col s12 m5">
                  <img alt="" className="content-product-img" src="https://github.com/IgorVieira/lws-tendas-rubyonrails/blob/master/app/assets/images/Sanfonadas/3x3/3x3_1.jpg?raw=true" />
                </div>
                <div className="col s12 m5">
                  <Slider
                    fullscreen={false}
                    options={{
                      duration: 500,
                      height: 400,
                      indicators: true,
                      interval: 6000
                    }}
                  >
                    <Slide image={<img alt="" src="https://github.com/IgorVieira/lws-tendas-rubyonrails/blob/master/app/assets/images/Sanfonadas/3x3/3x3_1.jpg?raw=true" />}>
                      <Caption placement="center">
                        <h3 className="deep-orange black-text center-align">
                          Tendas Personalizadas
                        </h3>
                        <h5 className="deep-orange lighten-2 light silver-text text-lighten-3 center-align">
                          3x3 Sanfonadas
                        </h5>
                      </Caption>
                    </Slide>
                    <Slide image={<img alt="" src="https://github.com/IgorVieira/lws-tendas-rubyonrails/blob/master/app/assets/images/Sanfonadas/3x3/3x3_2.jpg?raw=true" />}>
                      <Caption placement="left">
                        <h3 className="deep-orange black-text center-align">
                          Tendas Personalizadas
                        </h3>
                        <h5 className="deep-orange lighten-2 light silver-text text-lighten-3 center-align">
                          3x3 Sanfonadas
                        </h5>
                      </Caption>
                    </Slide>
                    <Slide image={<img alt="" src="https://github.com/IgorVieira/lws-tendas-rubyonrails/blob/master/app/assets/images/Sanfonadas/4,5x3/4.5x3_3.jpg?raw=true" />}>
                      <Caption placement="right">
                        <h3 className="deep-orange black-text center-align">
                          Tendas Personalizadas
                        </h3>
                        <h5 className="deep-orange lighten-2 light silver-text text-lighten-3 center-align">
                          4,5x3 Sanfonadas
                        </h5>
                      </Caption>
                    </Slide>
                    <Slide image={<img alt="" src="https://github.com/IgorVieira/lws-tendas-rubyonrails/blob/master/app/assets/images/Sanfonadas/4,5x3/4.5x3_4.jpg?raw=true" />}>
                      <Caption placement="center">
                        <h3 className="deep-orange black-text center-align">
                          Tendas Personalizadas
                        </h3>
                        <h5 className="deep-orange lighten-2 light silver-text text-lighten-3 center-align">
                          4,5x3 Sanfonadas
                        </h5>
                      </Caption>
                    </Slide>
                    <Slide image={<img alt="" src="https://github.com/IgorVieira/lws-tendas-rubyonrails/blob/master/app/assets/images/Sanfonadas/6x3/6x3_2.jpg?raw=true" />}>
                      <Caption placement="center">
                        <h3 className="deep-orange black-text center-align">
                          Tendas Personalizadas
                        </h3>
                        <h5 className="deep-orange lighten-2 light silver-text text-lighten-3 center-align">
                          6x3 Sanfonadas
                        </h5>
                      </Caption>
                    </Slide>
                    <Slide image={<img alt="" src="https://github.com/IgorVieira/lws-tendas-rubyonrails/blob/master/app/assets/images/Sanfonadas/6x3/6x3_3.jpg?raw=true" />}>
                      <Caption placement="center">
                        <h3 className="deep-orange black-text center-align">
                          Tendas Personalizadas
                        </h3>
                        <h5 className="deep-orange lighten-2 light silver-text text-lighten-3 center-align">
                          6x3 Sanfonadas
                        </h5>
                      </Caption>
                    </Slide>
                    <Slide image={<img alt="" src="https://github.com/IgorVieira/lws-tendas-rubyonrails/blob/master/app/assets/images/Sanfonadas/6x3/6x3_6.jpg?raw=true" />}>
                      <Caption placement="center">
                        <h3 className="deep-orange black-text center-align">
                          Tendas Personalizadas
                        </h3>
                        <h5 className="deep-orange lighten-2 light silver-text text-lighten-3 center-align">
                          6x3 Sanfonadas
                        </h5>
                      </Caption>
                    </Slide>
                  </Slider>
                </div>
              </div>
            </div>
          <Footer />
      </div>
    );
}

export default Personalizadas;
import React from 'react'
import { Link } from 'react-router-dom';

const ProductCpt = () => {
  return (
    <div class="container-product" >
      <div class="container">
        <div class="section" >
          <div className="row">
            <div className="content-tile">
              <h4  className="center white-text title-harvy">Produtos</h4>
              <hr />
            </div>
            <div className="col s12 m4">
              <div className="card ">
                <div className="card-image">
                  <img alt="sanfonda3x3x0"  src="https://github.com/IgorVieira/lws-tendas-rubyonrails/blob/master/app/assets/images/piramidais/15x15_piramidal/15x15_2.jpg?raw=true" />
                  <span className="card-title">Tenda Piramidal</span>
                </div>
                <div className="card-action">
                  <Link to='/piramides'>Veja mais</Link>
                </div>
              </div>
            </div>
            <div className="col s12 m4">
              <div className="card ">
                <div className="card-image">
                  <img alt="sanfonda3x3x1" src="https://github.com/IgorVieira/lws-tendas-rubyonrails/blob/master/app/assets/images/Sanfonadas/3x3/3x3_5.jpg?raw=true" />
                  <span className="card-title">Tenda Sanfonadas</span>
                </div>
                <div className="card-action">
                  <Link to='/sanfonadas'>Veja mais</Link>
                </div>
              </div>
            </div>
            <div className="col s12 m4">
              <div className="card ">
                <div className="card-image">
                  <img alt="sanfonda3x3x2" src="https://github.com/IgorVieira/lws-tendas-rubyonrails/blob/master/app/assets/images/Sanfonadas/3x3/3x3_2.jpg?raw=true" />
                  <span className="card-title">Tenda Personalizada</span>
                </div>
                <div className="card-action">
                  <Link to='/personalizadas'>Veja mais</Link>
                </div>
              </div>
            </div>
            <div className="col s12 m4">
              <div className="card ">
                <div className="card-image">
                  <img alt="sanfonda3x3x3" src="https://github.com/IgorVieira/lws-tendas-rubyonrails/blob/master/app/assets/images/tenda_sob_medida/tenda_1.jpg?raw=true" />
                  <span className="card-title">Tenda Sob Medida</span>
                </div>
                <div className="card-action">
                  <Link to='/sob_medidas'>Veja mais</Link>
                </div>
              </div>
            </div>
            <div className="col s12 m4">
              <div className="card ">
                <div className="card-image">
                  <img alt="sanfonda3x3x4" src="https://github.com/IgorVieira/lws-tendas-rubyonrails/blob/master/app/assets/images/tenda_galpao/galpao_4.jpg?raw=true" />
                  <span className="card-title">Tenda Galpão</span>
                </div>
                <div className="card-action">
                  <Link to='/galpoes'>Veja mais</Link>
                </div>
              </div>
            </div>
            <div className="col s12 m4">
              <div className="card ">
                <div className="card-image">
                  <img alt="sanfonda3x3x5" src="https://github.com/IgorVieira/lws-tendas-rubyonrails/blob/master/app/assets/images/barricadas_de_protecao/barricada_6.jpg?raw=true" />
                  <span className="card-title">Barricadas de Contenção</span>
                </div>
                <div className="card-action">
                  <Link to='/contencoes'>Veja mais</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export default ProductCpt
import FormComponent from '../components/Contact/index';

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'


function Contact(){
    return (
        <div>
            <Navbar/>
            <FormComponent/>
            <Footer />
        </div>

    );
}

export default Contact;


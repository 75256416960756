import { Slider, Slide, Caption } from 'react-materialize';
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'


function SobMedidas(){
    return (
        <div className="content-product">
          <Navbar/>
            <div className="section">
              <div className="row">
                <div className="col s12 m1"></div>
                <div className="col s12 m2">
                  <h4>Sob Medidas</h4>
                  <hr/>
                </div>
                <div className="col s12 m3"></div>
                <div className="col s12 m6"></div>
              </div>
              <div className="row">
                <div className="col s12 m1"></div>
                <div className="col s12 m5">
                  <img alt="" className="content-product-img" src="https://github.com/IgorVieira/lws-tendas-rubyonrails/blob/master/app/assets/images/tenda_sob_medida/tenda_1.jpg?raw=true" />
                </div>
                <div className="col s12 m5">
                  <Slider
                    fullscreen={false}
                    options={{
                      duration: 500,
                      height: 400,
                      indicators: true,
                      interval: 6000
                    }}
                  >
                    <Slide image={<img alt="" src="https://github.com/IgorVieira/lws-tendas-rubyonrails/blob/master/app/assets/images/tenda_sob_medida/tenda_1.jpg?raw=true" />}>
                      <Caption placement="center">
                        <h3 className="deep-orange black-text center-align">
                          Tendas Sob Medida
                        </h3>
                      </Caption>
                    </Slide>
                    <Slide image={<img alt="" src="https://github.com/IgorVieira/lws-tendas-rubyonrails/blob/master/app/assets/images/tenda_sob_medida/tenda_2.jpg?raw=true" />}>
                      <Caption placement="left">
                        <h3 className="deep-orange black-text center-align">
                          Tendas Sob Medida
                        </h3>
                      </Caption>
                    </Slide>
                    <Slide image={<img alt="" src="https://github.com/IgorVieira/lws-tendas-rubyonrails/blob/master/app/assets/images/tenda_sob_medida/tenda_3.jpg?raw=true" />}>
                      <Caption placement="right">
                        <h3 className="deep-orange black-text center-align">
                          Tendas Sob Medida
                        </h3>
                      </Caption>
                    </Slide>
                    <Slide image={<img alt="" src="https://github.com/IgorVieira/lws-tendas-rubyonrails/blob/master/app/assets/images/tenda_sob_medida/tenda_4.jpg?raw=true" />}>
                      <Caption placement="center">
                        <h3 className="deep-orange black-text center-align">
                          Tendas Sob Medida
                        </h3>
                      </Caption>
                    </Slide>
                    <Slide image={<img alt="" src="https://github.com/IgorVieira/lws-tendas-rubyonrails/blob/master/app/assets/images/tenda_sob_medida/tenda_5.jpg?raw=true" />}>
                      <Caption placement="center">
                        <h3 className="deep-orange black-text center-align">
                          Tendas Sob Medida
                        </h3>
                      </Caption>
                    </Slide>
                    <Slide image={<img alt="" src="https://github.com/IgorVieira/lws-tendas-rubyonrails/blob/master/app/assets/images/tenda_sob_medida/tenda_6.jpg?raw=true" />}>
                      <Caption placement="center">
                        <h3 className="deep-orange black-text center-align">
                          Tendas Sob Medida
                        </h3>
                      </Caption>
                    </Slide>
                  </Slider>
                </div>
              </div>
            </div>
          <Footer />
      </div>
    );
}

export default SobMedidas;
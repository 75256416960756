import React from 'react';
import  agrocanassa from "./assets/agrocanassa.jpg";
import  allegratche from "./assets/allegratche.jpg";
import  conopus from "./assets/conopus.jpg";
import  pintosrunners from "./assets/pintosrunners.jpg";


const CustomersCpt = () => {

  return (
    <div className="container-cases" id="cases">
      <div className="container">
        <div className="section" >
          <div className="row" >
            <div className="col s12 m12">
              <h4  className="center white-text title-harvy">Alguns de Nossos Clientes</h4>
              <hr />
              <div className="conetnt-cases-customers">
                <img  src={agrocanassa}  alt="agrocanassa" className="container-cases-customers"/>
                <img  src={allegratche} alt="allegratche" className="container-cases-customers"/>
                <img  src={conopus} alt="conopus" className="container-cases-customers"/>
                <img  src={pintosrunners} alt="pintosrunners" className="container-cases-customers"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomersCpt;
import { Slider, Slide, Caption } from 'react-materialize';
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'

function Sanfonadas(){
    return (
      <div className="content-product">
        <Navbar/>
          <div className="section">
            <div className="row">
              <div className="col s12 m1"></div>
              <div className="col s12 m1">
                <h4>Sanfonadas</h4>
                <hr/>
              </div>
              <div className="col s12 m4"></div>
              <div className="col s12 m6"></div>
            </div>
            <div className="row">
              <div className="col s12 m1"></div>
              <div className="col s12 m5">
                <img alt="" className="content-product-img" src="https://github.com/IgorVieira/lws-tendas-rubyonrails/blob/master/app/assets/images/Sanfonadas/2x2/2x2_1.jpg?raw=true"/>
              </div>
              <div className="col s12 m5">
                <Slider
                  fullscreen={false}
                  options={{
                    duration: 500,
                    height: 400,
                    indicators: true,
                    interval: 6000
                  }}
                >
                  <Slide image={<img alt="" src="https://github.com/IgorVieira/lws-tendas-rubyonrails/blob/master/app/assets/images/Sanfonadas/2x2/2x2_1.jpg?raw=true" />}>
                    <Caption placement="center">
                      <h3 className="deep-orange black-text center-align">
                        Tendas Sanfonadas
                      </h3>
                      <h5 className="deep-orange lighten-2 light silver-text text-lighten-3 center-align">
                        2x2 Sanfonadas
                      </h5>
                    </Caption>
                  </Slide>
                  <Slide image={<img alt="" src="https://github.com/IgorVieira/lws-tendas-rubyonrails/blob/master/app/assets/images/Sanfonadas/2x2/2x2_2.jpg?raw=true"/>}>
                    <Caption placement="left">
                      <h3 className="deep-orange black-text center-align">
                        Tendas Sanfonadas
                      </h3>
                      <h5 className="deep-orange lighten-2 light silver-text text-lighten-3 center-align">
                        2x2 Sanfonadas
                      </h5>
                    </Caption>
                  </Slide>
                  <Slide image={<img alt="" src="https://github.com/IgorVieira/lws-tendas-rubyonrails/blob/master/app/assets/images/Sanfonadas/3x2/3x2_1.jpg?raw=true" />}>
                    <Caption placement="right">
                      <h3 className="deep-orange black-text center-align">
                        Tendas Sanfonadas
                      </h3>
                      <h5 className="deep-orange lighten-2 light silver-text text-lighten-3 center-align">
                        3x2 Sanfonadas
                      </h5>
                    </Caption>
                  </Slide>
                  <Slide image={<img alt="" src="https://github.com/IgorVieira/lws-tendas-rubyonrails/blob/master/app/assets/images/Sanfonadas/3x2/3x2_2.jpg?raw=true" />}>
                    <Caption placement="center">
                      <h3 className="deep-orange black-text center-align">
                        Tendas Sanfonadas
                      </h3>
                      <h5 className="deep-orange lighten-2 light silver-text text-lighten-3 center-align">
                        3x2 Sanfonadas
                      </h5>
                    </Caption>
                  </Slide>
                </Slider>
              </div>
            </div>
          </div>
        <Footer />
      </div>
    );
}

export default Sanfonadas;
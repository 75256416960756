import React from 'react';
import { Slider, Slide, Caption } from 'react-materialize';

function AboutCpt () {
  return (
      <div className="container-about">
        <div className="container">
          <div className="section" id="how">
            <div className="row">
              <div className="col s12 m6">
              <h4 className="title-harvy center">Quem somos</h4>
              <hr />
              <p className="left-align light black-text">
                A LWS TENDAS vem se consolidando no mercado com o objetivo de entregar máxima qualidade, resistência e beleza aos seus clientes. Visando sempre proporcionar soluções praticas e econômicas. Com a missão de produzir tendas diferenciadas temos como meta principal proporcionar uma qualidade superior, e sempre levando em conta detalhes que ao final da confecção fazem a total diferença.
              </p>
            </div>
            <div className="col s12 m6">
              <Slider
                fullscreen={false}
                options={{
                  duration: 500,
                  height: 400,
                  indicators: true,
                  interval: 6000
                }}
              >
                <Slide image={<img alt="" src="https://github.com/IgorVieira/lws-tendas-rubyonrails/blob/master/app/assets/images/piramidais/15x15_piramidal/15x15_3.jpg?raw=true"/>}>
                  <Caption placement="center">
                    <h3 className="deep-orange black-text center-align">Tendas para o que você quer...</h3>
                    <h5 className=" deep-orange lighten-2 light silver-text text-lighten-3 center-align">como você precisa</h5>
                  </Caption>
                </Slide>
                <Slide image={<img alt="" src="https://github.com/IgorVieira/lws-tendas-rubyonrails/blob/master/app/assets/images/tenda_galpao/galpao_4.jpg?raw=true" />}>
                  <Caption placement="left">
                    <h3 className="deep-orange black-text center-align">Trazendo diferencial...</h3>
                    <h5 className=" deep-orange lighten-2 light silver-text text-lighten-3 center-align"> e concretizando o seu negócio.</h5>
                  </Caption>
                </Slide>
                <Slide image={<img alt="" src="https://github.com/IgorVieira/lws-tendas-rubyonrails/blob/master/app/assets/images/barricadas_de_protecao/barricada_6.jpg?raw=true"/>}>
                  <Caption placement="right">
                    <h3 className="deep-orange black-text center-align">Usando o melhor... </h3>
                    <h5 className=" deep-orange lighten-2 light silver-text text-lighten-3 center-align">para lhe atender.</h5>
                  </Caption>
                </Slide>
                <Slide image={<img alt="" src="https://github.com/IgorVieira/lws-tendas-rubyonrails/blob/master/app/assets/images/tenda_sob_medida/tenda_1.jpg?raw=true"/>}>
                  <Caption placement="center">
                    <h3 className="deep-orange black-text center-align">Buscando ser o que você precisa...</h3>
                    <h5 className=" deep-orange lighten-2 light silver-text text-lighten-3 center-align"> para o seu negócio!</h5>
                  </Caption>
                </Slide>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutCpt